import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <section className="web-footer">
    <div className="footer-box">
      <img alt="" src={require('../images/logo.png')} className="footer-logo" />

      <div className="footer-nav-wrap">
        <ul className="footer-nav">
          <li className="active">
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="services">Services</Link>
          </li>
          <li>
            <Link to="about">About</Link>
          </li>
          <li>
            <Link to="contact">Contact</Link>
          </li>
          {/* <li>
            <Link to="blog">Blog</Link>
          </li> */}
        </ul>
      </div>

      <p className="copyright">
        {'\u00A9'} {new Date().getFullYear()} Firstbyte Digital Solutions LLP
      </p>
    </div>
  </section>
);
export default Footer;
