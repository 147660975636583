import React from 'react';
// import './mobile-menu.css';

const Mobilemenu = (props) => (
  <button className="menu-btn" onClick={props.click}>
    <div className="menu-line"></div>
    <div className="menu-line"></div>
    <div className="menu-line"></div>
  </button>
);

export default Mobilemenu;
