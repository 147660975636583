/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

// import Header from './header';
import Toolbar from './toolbar';
import Footer from './footer';
import SideDrawer from './side-drawer';
import Backdrop from './backdrop';
// import './layout.css';

// const Layout = ({ children }) => {
//   return (
//     <div style={{ height: '100%' }}>
//       {/* <Header /> */}
//       <Toolbar />
//       <SideDrawer />
//       <Backdrop />
//       <main>{children}</main>
//       <Footer />
//     </div>
//   );
// };

class Layout extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  closeHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  render() {
    const { children } = this.props;
    // let sideDrawer;
    // let backdrop;
    // if (this.state.sideDrawerOpen) {
    //   sideDrawer = <SideDrawer />;
    //   backdrop = <Backdrop />;
    // }
    const { sideDrawerOpen } = this.state;
    return (
      <div style={{ height: '100%' }}>
        {/* <Header /> */}
        <Toolbar drawerClickHandler={this.drawerHandler} />
        {/* if (this.state.sideDrawerOpen){' '}
        {
          <div>
            <SideDrawer />
            <Backdrop />
          </div>
        } */}

        <SideDrawer
          show={this.state.sideDrawerOpen}
          close={this.closeHandler}
        />

        {sideDrawerOpen ? (
          <div>
            <Backdrop click={this.backdropHandler} />
          </div>
        ) : null}

        <main>{children}</main>
        <Footer />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

// export default Layout;
