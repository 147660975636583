import React from 'react';
import { Link } from 'gatsby';

const SideDrawer = (props) => {
  let drawerClasses = 'side-drawer';
  if (props.show) {
    drawerClasses = 'side-drawer open';
  }
  return (
    <nav className={drawerClasses}>
      <div className="close-area">
        <img
          className="close-btn"
          src={require('../images/close.svg')}
          onClick={props.close}
          alt="close"
        />
      </div>
      <ul>
        <li className="active">
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="about">About</Link>
        </li>
        <li>
          <Link to="services">Services</Link>
        </li>
        <li>
          <Link to="contact">Contact</Link>
        </li>
        {/* <li>
          <Link to="blog">Blog</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default SideDrawer;

/***<div>Icons made by <a href="https://www.flaticon.com/authors/roundicons" title="Roundicons">Roundicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*****/
