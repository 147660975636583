import React from 'react';
import { Link } from 'gatsby';
import Mobilemenu from './mobile-menu';

const Toolbar = (props) => (
  <header className="toolbar">
    <div className="row">
      <nav className="toolbar-nav">
        <div>
          <Link to="/">
            <img
              className="toolbar-logo"
              src={require('../images/logo.png')}
              alt="Menu"
            ></img>
          </Link>
          <Link to="/" className="company-link">
            <span className="company">Firstbyte</span>
          </Link>
        </div>

        <div className="toolbar-nav-items">
          <ul>
            <li>
              <Link to="/" activeClassName={'active'}>
                Home
              </Link>
            </li>
            <li>
              <Link to="about" activeClassName={'active'}>
                About
              </Link>
            </li>
            <li>
              <Link to="services" activeClassName={'active'}>
                Services
              </Link>
            </li>
            <li>
              <Link to="contact" activeClassName={'active'}>
                Contact
              </Link>
            </li>
            {/* <li>
              <Link to="blog" activeClassName={'active'}>
                Blog
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="toolbar-toggle-btn">
          <Mobilemenu click={props.drawerClickHandler} />
        </div>
      </nav>
    </div>
  </header>
);

export default Toolbar;
